<template>
  <svg role="img" class="nypr-logo-3d" aria-label="NYPR Logo" width="356.7" height="291.7" viewBox="0 0 364.7 291.7"
    fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <polygon class="st0" points="124.9 208.4 209.5 14 209.5 13.9 124.8 208.4 124.9 208.4 124.9 208.4" />
      <polygon class="st0" points="209.5 14 354.7 4.2 354.6 4.2 209.5 13.9 209.5 14 209.5 14" />
      <path class="st0" d="M236.7,291.3l-111.9-82.9h0s111.9,82.9,111.9,82.9h0Z" />
      <polygon class="st0" points="354.7 4.2 236.7 291.3 236.7 291.3 354.6 4.2 354.7 4.2 354.7 4.2" />
      <polyline class="st0" points="245.3 178.8 245.4 178.8 245.4 178.8" />
      <line class="st0" x1="231.6" y1="157.7" x2="231.6" y2="157.7" />
      <path class="st0"
        d="M231.6,157.7l13.7,21.1c-21.2,12.7-51.3,10.8-64.3-13.1-15.1-27.7,7-63,35.7-69.7,21.1-5,44.6,4.2,51.6,25.7l-26.3,9.5c-12.8-40.2-65.1,7.8-37.4,27.9,8.4,6.1,18.8,3.3,27-1.4h0Z" />
    </g>
    <g>
      <polygon class="st0" points="25.6 134.7 69.9 37.7 69.9 37.7 25.5 134.7 25.6 134.7 25.6 134.7" />
      <polygon class="st0" points="69.9 37.7 109.9 38.3 109.9 38.3 69.9 37.7 69.9 37.7 69.9 37.7" />
      <polygon class="st0" points="56.2 157.5 25.6 134.7 25.5 134.7 56.2 157.5 56.2 157.5 56.2 157.5" />
      <polygon class="st0" points="109.9 38.3 56.2 157.5 56.2 157.5 109.9 38.3 109.9 38.3 109.9 38.3" />
      <polygon class="st0" points="78.7 70.6 68.1 93.9 68.1 93.9 78.7 70.5 78.7 70.6 78.7 70.6" />
      <polygon class="st0" points="68.1 93.9 69 68.7 68.9 68.7 68.1 93.9 68.1 93.9 68.1 93.9" />
      <polygon class="st0" points="69 68.7 63.4 67.7 63.3 67.6 68.9 68.7 69 68.7 69 68.7" />
      <polygon class="st0" points="63.4 67.7 44.8 108.4 44.8 108.4 63.3 67.6 63.4 67.7 63.4 67.7" />
      <path class="st0" d="M44.8,108.4l5.4,2.6c-1.8-.9-3.6-1.7-5.4-2.6h0Z" />
      <polygon class="st0" points="50.2 110.9 60.7 87.7 60.7 87.7 50.1 110.9 50.2 110.9 50.2 110.9" />
      <polygon class="st0" points="60.7 87.7 59.3 115.3 59.3 115.3 60.7 87.7 60.7 87.7 60.7 87.7" />
      <path class="st0" d="M59.3,115.3l5.7,2.7c-1.9-.9-3.8-1.8-5.7-2.7h0Z" />
      <polygon class="st0" points="85.8 71.9 78.7 70.6 78.7 70.5 85.8 71.9 85.8 71.9 85.8 71.9" />
      <polygon class="st0" points="65 118 85.8 71.9 85.8 71.9 65 118 65 118 65 118" />
    </g>
    <g>
      <polygon class="st0" points="61.8 161.6 112.1 49.7 112 49.7 61.8 161.6 61.8 161.6 61.8 161.6" />
      <polygon class="st0" points="112.1 49.7 178.7 54.1 178.7 54 112 49.7 112.1 49.7 112.1 49.7" />
      <polygon class="st0" points="114.6 200.7 61.8 161.6 61.8 161.6 114.5 200.7 114.6 200.7 114.6 200.7" />
      <polygon class="st0" points="178.7 54.1 114.6 200.7 114.5 200.7 178.7 54 178.7 54.1 178.7 54.1" />
      <polygon class="st0" points="137.6 80.8 120.1 99 120.1 99 137.5 80.8 137.6 80.8 137.6 80.8" />
      <polygon class="st0" points="120.1 99 121 77.7 121 77.7 120.1 99 120.1 99 120.1 99" />
      <polygon class="st0" points="121 77.7 109.7 75.6 109.7 75.6 121 77.7 121 77.7 121 77.7" />
      <polygon class="st0"
        points="108.3 112.1 98.7 133.7 98.7 133.7 108.3 112 109.7 75.6 109.7 75.6 108.3 112.1 108.3 112.1" />
      <path class="st0" d="M98.7,133.7l10.1,4.8c-3.4-1.6-6.7-3.2-10.1-4.8h0Z" />
      <polygon class="st0" points="151.7 83.4 137.6 80.8 137.5 80.8 151.6 83.4 151.7 83.4 151.7 83.4" />
      <polygon class="st0"
        points="118.9 115.5 151.7 83.4 151.6 83.4 118.9 115.5 108.8 138.4 108.8 138.5 118.9 115.5 118.9 115.5" />
    </g>
    <g>
      <polygon class="st0" points="26.7 98.1 33.1 76.7 33.1 76.6 26.7 98.1 26.7 98.1 26.7 98.1" />
      <polygon class="st0" points="33.1 76.7 21.1 95.5 21 95.5 33.1 76.6 33.1 76.7 33.1 76.7" />
      <path class="st0" d="M21.1,95.5c-1.1-.5-2.1-1-3.2-1.5,1.1.5,2.1,1,3.2,1.5h0Z" />
      <polygon class="st0" points="17.9 94 28.7 59.7 28.7 59.7 17.8 94 17.9 94 17.9 94" />
      <polygon class="st0" points="28.7 59.7 32.7 60.4 32.7 60.4 28.7 59.7 28.7 59.7 28.7 59.7" />
      <polygon class="st0" points="32.7 60.4 26.3 80.3 26.3 80.3 32.7 60.4 32.7 60.4 32.7 60.4" />
      <polygon class="st0" points="26.3 80.3 38.2 61.3 38.2 61.2 26.3 80.3 26.3 80.3 26.3 80.3" />
      <polygon class="st0" points="38.2 61.3 42 61.9 42 61.9 38.2 61.2 38.2 61.3 38.2 61.3" />
      <polygon class="st0" points="42 61.9 35.5 83.4 35.5 83.4 42 61.9 42 61.9 42 61.9" />
      <polygon class="st0" points="35.5 83.4 48.1 63.1 48.1 63.1 35.5 83.4 35.5 83.4 35.5 83.4" />
      <polygon class="st0" points="48.1 63.1 53.3 64 53.3 64 48.1 63.1 48.1 63.1 48.1 63.1" />
      <path class="st0" d="M30.4,99.8c-1.2-.6-2.5-1.1-3.7-1.7,1.2.6,2.5,1.1,3.7,1.7h0Z" />
      <polygon class="st0" points="53.3 64 30.4 99.8 30.3 99.8 53.3 64 53.3 64 53.3 64" />
    </g>
    <g>
      <polygon class="st0" points="2 117.3 9 102.1 9 102.1 2 117.3 2 117.3 2 117.3" />
      <path class="st0" d="M9,102.1l21.3,12.1c-7.1-4-14.2-8.1-21.3-12.1h0Z" />
      <polygon class="st0" points="22.1 132.2 2 117.3 2 117.3 22.1 132.2 22.1 132.2 22.1 132.2" />
      <polygon class="st0" points="30.3 114.2 22.1 132.2 22.1 132.2 30.3 114.2 30.3 114.2 30.3 114.2" />
    </g>
  </svg>
</template>

<style>
.nypr-logo-3d .st0 {
  fill: none;
  stroke: #ffff;
  stroke-miterlimit: 10;
  stroke-width: 0.2px;
}
</style>
